import { render, staticRenderFns } from "./packingWorkPg.vue?vue&type=template&id=6def28d0&scoped=true&"
import script from "./packingWorkPg.vue?vue&type=script&lang=js&"
export * from "./packingWorkPg.vue?vue&type=script&lang=js&"
import style0 from "./packingWorkPg.vue?vue&type=style&index=0&id=6def28d0&lang=less&scoped=true&"
import style1 from "./packingWorkPg.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6def28d0",
  null
  
)

export default component.exports